import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faCalendarAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MfaActions } from 'app/profile/models/mfa';
import { PaymentMethodsActions } from 'app/profile/store/actions';
import * as fromProfile from 'app/profile/store/reducers';
import { AddressFormComponent } from 'app/shared/components/address/address-form.component';
import { TwoStepSecurityModal } from 'app/shared/containers/two-step-security/two-step-security-modal';
import { CreditCardHelper } from 'app/shared/helpers/credit-card.helper';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';
import { GeneralHelper } from 'app/shared/helpers/general.helper';
import { PaymentInformation } from 'app/shared/models/payment-information';
import * as fromRoot from 'reducers';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'edit-payment',
  templateUrl: './edit-payment.component.html',
  styleUrls: ['./edit-payment.component.css']
})
export class EditPaymentComponent implements OnInit, OnDestroy {
  @ViewChild(AddressFormComponent, { static: false }) addressForm: AddressFormComponent;
  @Input() requestMFA = false;
  @Input() userData;
  @Input() card;
  @Input() isPopup = true;
  @Output() updatedCard = new EventEmitter();
  @Output() cancelPressed = new EventEmitter();

  paymentInformation = new PaymentInformation({
    billingAddressRequired: true
  });
  user: any;
  userTools: any[];
  private ngUnsubscribe = new Subject<void>();
  error = '';
  loading = false;
  icons = { faSpinner, faCalendarAlt };
  isExpired: boolean;
  updatedPressed: boolean = false;
  paymentForm: FormGroup = this.formBuilder.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    expiration: ['', Validators.compose([Validators.required, CreditCardHelper.expireDateValidator()])],
  });

  cardActionSuccess$ = this.store.select(fromProfile.getPaymentMethodsSuccess);
  cardActionError$ = this.store.select(fromProfile.getPaymentMethodsError);

  constructor(
    public activeModal: NgbActiveModal,
    private generalHelper: GeneralHelper,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private store: Store<fromRoot.State>,
    private formBuilder: FormBuilder,
    public formErrorsHelper: FormErrorsHelper,
  ) {}

  ngOnInit() {
    if (this.isPopup) {
      this.store.dispatch(new PaymentMethodsActions.Reset());
    }
    this.paymentInformation.firstName = this.card.first_name;
    this.paymentInformation.lastName = this.card.last_name;
    this.paymentInformation.address.street = this.card.address;
    this.paymentInformation.address.city = this.card.city;
    this.paymentInformation.address.state = this.card.state;
    this.paymentInformation.address.zipCode = this.card.zip ? this.card.zip.toString() : '';
    this.paymentInformation.address.country = this.card.country;
    this.isExpired = CreditCardHelper.isExpired(this.card.expiration_date);
    this.paymentForm.patchValue({
      firstName: this.card.first_name,
      lastName: this.card.last_name,
    });

    this.listenForPaymentFailure();
    this.listenForPaymentSuccess();
  }

  listenForPaymentFailure(){
    this.cardActionError$.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((error: any) => {
          if (error && error.error_description) {
            this.error = error.error_description
            this.loading = false;
          }
      });
  }

  listenForPaymentSuccess() {
    this.cardActionSuccess$.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe((response: any) => {
        if (response) {
          this.loading = false;
          if (this.isPopup) {
            this.activeModal.close(true);
          } else if (this.updatedPressed){
            this.updatedCard.emit(response)
          }
        }
      });
  }

  onAddPaymentMethodClick(): void {
    this.loading = true;
    this.error = '';
    this.generalHelper.touchForms([this.paymentForm, this.addressForm.addressForm]);

    if (this.paymentForm.valid &&  this.addressForm.addressForm.valid) {
      if (this.requestMFA) {
        const twoStepSecurityModal = this.modalService.open(TwoStepSecurityModal, { backdrop : 'static', keyboard : false, size: 'lg' });
        twoStepSecurityModal.componentInstance.userData = this.userData;
        twoStepSecurityModal.componentInstance.action = MfaActions.MFA_PROFILE_UPDATE;
        twoStepSecurityModal.result.then(
          (success) => {
            this.editCreditCard(this.card.token);
          },
          (e) => {
            this.loading = false;
            this.error = this.translateService.instant('errors.mfa_required');
          }
        );
      } else {
        this.editCreditCard(this.card.token);
      }
    } else {
      this.loading = false;
    }
  }

  private editCreditCard(token) {
    try {
      const card = this.mapCreditCardParams(token, this.paymentForm.value, this.addressForm.addressForm.value);
      this.loading = true;
      this.updatedPressed = true;
      this.store.dispatch(new PaymentMethodsActions.Edit({ card }));
    } catch (error) {
      this.loading = false;
    }
  }

  get showIsBusinessCheckbox() {
    return this.userTools.indexOf('travel_manager') !== -1;
  }

  mapCreditCardParams(token, paymentData, addressData): any {
    return {
      token,
      expiration_month: paymentData.expiration.split('/')[0],
      expiration_year: `20${paymentData.expiration.split('/')[1]}`,
      first_name: paymentData.firstName,
      last_name: paymentData.lastName,
      address: addressData.street,
      zip: addressData.zipCode,
      city: addressData.city,
      country: addressData.country,
      state: addressData.state,
    };
  }

  formatDate(event) {
    const backspace = event.inputType === 'deleteContentBackward';
    const control = this.expiration;
    const date = control.value.replace(/\/|\D/g, '');
    let formattedDate = date;

    if (date.length === 1 && !backspace && parseInt(date, 10) > 1) {
      formattedDate = `0${date}/`;
    } else if (date.length === 2 && backspace) {
      formattedDate = date.substr(0, 1);
    } else if (date.length >= 2) {
      const month = date.substr(0, 2);
      const year = date.substr(2, 2);
      this.paymentInformation.expirationMonth = month;
      this.paymentInformation.expirationYear = `20${year}`;
      this.paymentInformation.expiration = `${month}/${year}`;
      formattedDate = `${month}/${year}`;
    }

    this.paymentInformation.formatExpirationDate(formattedDate);
    control.setValue(formattedDate);
  }

  get firstName() {
    return this.paymentForm.get('firstName');
  }

  get lastName() {
    return this.paymentForm.get('lastName');
  }

  get expiration() {
    return this.paymentForm.get('expiration');
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  onCancelPressed() {
    if (this.isPopup) {
      this.activeModal.dismiss();
    } else {
      this.cancelPressed.emit();
    }
  }

  closeError(){
    this.error = '';
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
