<div class="modal-header py-0 ps-0">
  <div class="profile-title primary-content">{{ 'payment.edit_payment_method' | translate }}</div>
  <button type="button" class="btn-close pe-0" aria-label="Close" (click)="dismiss()">

  </button>
</div>
<div class="modal-body pe-0 pb-4 pt-0">
  <ngb-alert *ngIf="error" type="danger" (closed)="closeError()">{{ error }}</ngb-alert>
  <div class="row align-items-center mb-3">
    <div class="col-md-2 d-none d-md-block" [ngClass]="{'grayscale' : isExpired}">
      <img class="img-fluid" [src]="'angular_assets/images/icons/' + card.payment_method_id + '.png'" />
    </div>
    <div class="col-md-5 col-6 mt-2 mt-md-0">
      <div class="secondary-content d-none d-md-block">{{ 'payment.card_number' | translate }}</div>
      <div class="secondary-content text-uppercase d-block d-md-none">{{ card.payment_method_id }}</div>
      <div class="tertiary-content">{{ 'payment.ending_in' | translate }} {{ card.card_number }}</div>
    </div>
  </div>
  <form [formGroup]="paymentForm">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group form-floating">
          <input type="text" class="form-control with-background" formControlName="firstName" autocomplete="given-name" data-cy="paymentFirstName">
          <label for="first-name">{{ 'payment.first_name' | translate }}</label>
            <div class='error-message'
            *ngIf="firstName?.errors !== null && (firstName.touched)"
            [innerHtml]="formErrorsHelper.errorMessage(firstName, ('payment.first_name' | translate))">
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group form-floating">
          <input type="text" class="form-control with-background" formControlName="lastName" data-cy="paymentLastName" autocomplete="family-name">
          <label for="last-name">{{ 'payment.last_name' | translate }}</label>
          <div class='error-message'
            *ngIf="lastName?.errors !== null && (lastName.touched)"
            [innerHtml]="formErrorsHelper.errorMessage(lastName, ('payment.last_name' | translate))">
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <div class="position-relative form-floating">
            <input (input)="formatDate($event)" type="text" class="form-control inspectlet-sensitive with-background" formControlName="expiration" autocomplete="cc-exp" data-cy="paymentExpirationDate" data-sl="mask">
            <label for="expiration">{{ 'payment.expiration_date' | translate }} (MM/YY)</label>
            <fa-icon [icon]="icons.faCalendarAlt" class="input-icon"></fa-icon>
          </div>
          <div class='error-message' data-cy="error-card-expiration"
            *ngIf="expiration?.errors !== null && (expiration.touched)"
            [innerHtml]="(formErrorsHelper.errorMessage(expiration, ('payment.expiration_date_short' | translate))  | translate)">
          </div>
        </div>
      </div>
    </div>
  </form>
  <address-form #addressForm
    [address]="paymentInformation.address"
    [showStreet]="true"
    [showCity]="true"
    [showCountry]="true"
    [showState]="true"
    [showZip]="true">
  </address-form>
</div>
<div class="edit-payment-footer">
  <button type="button" (click)="onCancelPressed()" class="me-3 btn btn-outline-light text-dark border">
    {{ 'flights.search.modals.cancel' | translate }}
  </button>
  <button ngbAutofocus type="button" class="btn btn-md btn-primary" [disabled]="loading" (click)="onAddPaymentMethodClick()" data-cy="save-edit-cc">
    <fa-icon *ngIf="loading" [icon]="icons.faSpinner" class="rotation-animation fa-spin"></fa-icon>
    {{ 'payment.update_card' | translate }}
  </button>
</div>
