import {
    Component,
    HostListener,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Store, select } from '@ngrx/store';
import { AuthApiActions } from 'app/auth/store/actions';
import * as fromAuth from 'app/auth/store/reducers';
import { featureFlags } from 'app/shared/models/featureFlags';
import { AnalyticsService } from 'app/shared/services/analytics/analytics.service';
import { FeatureFlagsService } from 'app/shared/services/featureFlags/featureFlags.service';
import { StoryblokService } from 'app/shared/services/storyblok/storyblok.service';
import * as fromRoot from 'reducers';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'home',
  templateUrl: './home.html',
  styleUrls: ['./home.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomePage implements OnInit, OnDestroy {
  public story;
  schema = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    name: 'ID90 Travel',
    url: 'https://www.id90travel.com'
  };
  userAccount$ = this.store.pipe(select(fromAuth.selectAuthStatusState));
  sessionTimeOut$ = this.store.pipe(select(fromAuth.getSessionTimeOut));
  loggedIn$ = this.store.pipe(select(fromAuth.getLoggedIn));
  featureFlags = featureFlags;
  isCollapsed = false;
  loading = false;
  loggedIn = false;
  user = null;
  icons = { faBars, faXmark };
  scrollPosition = 0;

  public storyBlokSub = new BehaviorSubject(null);

  private ngUnsubscribe = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private store: Store<fromRoot.State>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private featureFlagsService: FeatureFlagsService,
    private analyticsService: AnalyticsService,
    private storyblokService: StoryblokService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    combineLatest([this.route.queryParams, this.sessionTimeOut$, this.userAccount$, this.loggedIn$])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([queryParams, sessionTimeout, account, loggedIn]) => {
        this.user = account.user;
        this.loggedIn = loggedIn;
        this.loading = (!sessionTimeout && loggedIn) || queryParams.jwt || queryParams.sso || queryParams.adminLogin || queryParams.sso_token;
      });
    this.checkLoggedInUser();
  }

  // If user is logged in, redirect to the specific page
  async checkLoggedInUser() {
    this.store.dispatch(new AuthApiActions.ClearRedirectUserURL());
    if (this.loggedIn) {
      let redirect = this.activatedRoute.snapshot.queryParams['redirect'] || this.user.member.redirect;
      if (!this.featureFlagsService.isFeatureEnabled(redirect?.split('/')[1])) {
        redirect = '/search';
      }
      return this.router.navigate([decodeURIComponent(redirect)]);
    } else {
      this.analyticsService.logPageView('Home');
      this.listenForStoryBlok();
      const data = await this.storyblokService.getStory('landing-page', { version: environment.storyblok.version as 'draft' | 'published' });
      if (data.error) {
        this.router.navigate(['login']);
        return;
       }
      this.story = data.story;
    }
  }

  trackButtonClick(contentType: string, contentId: string) {
    this.analyticsService.logContent({
      content_type: `HomePage - ${contentType}`,
      content_id: contentId
    });
  }

  getTrustedCompanyLogos(content) {
    let companyLogoUrls = [];
    content.forEach(item => { companyLogoUrls.push(item.filename) });
    return companyLogoUrls;
  }

  parseRichText(richText: any): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.storyblokService.parseTextRich(richText));
  }

  getArrayByComponentType(data: any[], componentName: string): any[] {
    return data.filter(item => item.component === componentName);
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const navbarDesktop = <HTMLElement> document.getElementsByClassName('navbar-desktop')[0];

    if (navbarDesktop) {
      if (this.scrollPosition > 100) {
        navbarDesktop.classList.add('translucent-navbar');
      } else {
        navbarDesktop.classList.remove('translucent-navbar');
      }
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  listenForStoryBlok() {
    this.storyblokService.listenForChanges$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
      if (!event || !this.story) { return }
      if (this.story && event?.story?.content?._uid === this.story?.content._uid && event) {
        this.story = event.story;
      }
    });
  }

  getImageUrlResized(url: string, resizeWidth: number = 600) {
    return `${url}/m/${resizeWidth}x0`;
  }
}
