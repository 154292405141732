import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CardEligibleTravelers } from 'app/flights/models/searches.interface';
import { EligiblesTravelers } from 'app/profile/models/eligibles-travelers';
import { FirebaseHelper } from 'app/shared/helpers/firebase.helper';
import { GeneralHelper } from 'app/shared/helpers/general.helper';
import { InviteTravelerHelper } from 'app/shared/helpers/invite-traveler.helper';
import { ConfigFlags } from 'app/shared/models/configFlags';
import { ConfirmationDialogService } from 'app/shared/services/confirmation-dialog/confirmation-dialog.service';
import { EligibleTravelersService } from 'app/shared/services/eligible-travelers/eligible-travelers.service';
import * as fromRoot from 'reducers';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import * as fromAuth from '../../../auth/store';
import { EligibleTravelerComponent } from './eligible-traveler/eligible-traveler.component';
import { InviteEligibleTravelerModal } from './invite-eligible-traveler-modal/invite-eligible-traveler-modal';

@Component({
  selector: 'eligible-travelers',
  templateUrl: './eligible-travelers.html',
  styleUrls: ['./eligible-travelers.scss'],
  encapsulation: ViewEncapsulation.None
})

export class EligibleTravelersComponent implements OnInit, OnDestroy {
  @Input() configFlags: ConfigFlags;
  @Input() featureFlags: any;
  eligibleTravelers: EligiblesTravelers[];
  @Output() showTabs = new EventEmitter();
  @ViewChild('eligibleTravelerComponent', { static: false }) eligibleTravelerModal: EligibleTravelerComponent;
  error = '';
  success = '';
  loading = true;
  formConfigs = {};
  relationships = {};
  enableModify = false;
  canInvite = false;
  showBasicGroup = false;
  loadingBasicGroup = true;
  icons = { faChevronLeft };
  user;
  user$ = this.store.pipe(select(fromAuth.getUser));
  private ngUnsubscribe = new Subject<void>();

  constructor(
    private modalService: NgbModal,
    private store: Store<fromRoot.State>,
    private eligibleTravelersService: EligibleTravelersService,
    private confirmationDialogService: ConfirmationDialogService,
    private translate: TranslateService,
    private inviteTravelerHelper: InviteTravelerHelper,
    private firebaseHelper: FirebaseHelper,
    private generalHelper: GeneralHelper,
  ) { }

  async ngOnInit() {
    this.getTravelersInformation();
    const basicGroupAirlines = await this.firebaseHelper.getKey('flightsShowGrupoBasico');
    this.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((u) => {
      this.user = u;
      this.showBasicGroup = !!basicGroupAirlines.airlines.includes(u.account.airlineCode);
      this.loadingBasicGroup = false;
    });
  }

  getTravelersInformation() {
    this.eligibleTravelersService.getTravelers().pipe(take(1)).subscribe((response) => {
      if (response) {
        this.canInvite = this.inviteTravelerHelper.canInvite(this.configFlags, this.featureFlags);
        this.eligibleTravelers = response.travelers;
        this.formConfigs = response.config;
        this.relationships = response.relationships;
        this.enableModify = response.is_in_open_enrollment;
        this.loading = false;
      }
    });
  }

  async onAddTravelerOpen() {
    const modalRef = this.modalService.open(EligibleTravelerComponent, { size: 'lg' });
    modalRef.componentInstance.travelerId = null;
    modalRef.componentInstance.externalConfigs = this.formConfigs;
    modalRef.componentInstance.relations = this.relationships;
    modalRef.componentInstance.enableModify = this.enableModify;
    modalRef.componentInstance.showBasicGroup = false;
    modalRef.result.then(
      (result) => {
        this.loading = true;
        this.getTravelersInformation();
        if (result.success) {
          this.success = result.success;
        }
        if (result.error) {
          this.error = result.error;
        }
      },
      (error) => {
        if (error !== 1) this.error = error;
      }
    );
  }

  openInviteModal(travelerId) {
    const modalRef = this.modalService.open(InviteEligibleTravelerModal, { windowClass: 'modal-md' });
    modalRef.componentInstance.travelerId = travelerId;
    modalRef.result.then(() => {
      this.success = this.translate.instant('profile.eligible_travelers.success_message');
      this.loading = true;
      this.getTravelersInformation();
    },                   (error) => {
      if (error !== 1) this.error = error;
    });
  }

  onEditOpen(params) {
    const { tab, travelerId } = params;
    const modalRef = this.modalService.open(EligibleTravelerComponent, { size: 'lg' });
    modalRef.componentInstance.travelerId = travelerId;
    modalRef.componentInstance.enableModify = this.enableModify;
    modalRef.componentInstance.showBasicGroup = this.showBasicGroup;
    modalRef.componentInstance.active = tab;
    modalRef.componentInstance.eligibleTravelers = this.eligibleTravelers;
    modalRef.componentInstance.canInvite = this.canInvite;
    modalRef.result.then(
      async (result) => {
        this.getTravelersInformation();
        if (result.success) {
          this.success = result.success;
        }
        if (result.error) {
          this.error = result.error;
        }
        this.generalHelper.scrollToHeader();
      },
      (error) => {
        if (error !== 1) this.error = error;
      }
    );
  }

  async onSubmit() {
    const confirmationDialog = await this.confirmationDialogService.confirm({
      title: 'profile.travelers.submit_alert_title',
      message: 'profile.travelers.submit_alert_text',
      btnOkText: 'profile.travelers.traveler_confirm',
      btnCancelText: 'profile.travelers.traveler_cancel'
    });
    if (confirmationDialog) {
      try {
        this.loading = true;
        await this.eligibleTravelersService.submitTravelerList({}).toPromise();
        this.enableModify = false;
        this.loading = false;
        this.success = await this.translate.get('profile.travelers.submit_success').toPromise();
      } catch (error) {
        this.error = await this.translate.get('profile.travelers.submit_error').toPromise();
      }
    }
  }

  modifyStoredTraveler(id: string, field: string, content: string) {
    const index = this.eligibleTravelers.findIndex((item: CardEligibleTravelers) => id === item.id);
    this.eligibleTravelers[index] = { ...this.eligibleTravelers[index], [field]: content };
  }

  closeAlert() {
    this.success = '';
    this.error = '';
  }

  backToTabs() {
    this.showTabs.emit();
  }

  get canInviteEligibleTravelers() {
    return this.featureFlags.includes('invite_eligible_traveler');
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
