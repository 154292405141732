<div class="two-step-modal" data-cy="mfa-two-step-modal" *ngIf="showEmail || isPhoneValid">
  <ngb-alert type="danger" *ngIf="errorMessage" (closed)="errorMessage = ''">
    <span [innerHtml]="errorMessage"></span>
  </ngb-alert>
  <button type="button" aria-label="Close" data-cy="close-button" class="btn text-black-50 float-end"
    [ngClass]="fromLogin ? 'sign-out-button' : 'close-button'"
    (click)="dismiss()">
    <span aria-hidden="true">{{ fromLogin ? ('sign_out' | translate) : '&times;' }}</span>
  </button>
  <loading-indicator *ngIf="loading" [fullScreen]="false"></loading-indicator>
  <ng-container *ngIf="!loading && template === modalTabs.ENROLL_TEMPLATE">
    <div class="modal-header pb-0">
      <h3 class="modal-title">{{ 'profile.login_and_security.two_step_security.title' | translate }}</h3>
    </div>
    <div class="modal-body container" data-cy="enroll-template">
      <p class="secondary-content pb-1">{{ 'profile.login_and_security.two_step_security.enroll.content_1' | translate }}</p>
      <p class="secondary-content pb-3">{{ 'profile.login_and_security.two_step_security.enroll.content_2' | translate }}</p>
      <div class="row">
        <div class="col-12">
          <div class="form-group form-floating">
            <input type="email" class="form-control with-background" placeholder="" [value]="getEmailString()" readonly="true" data-cy="enroll-template-email">
            <label>{{'contact.email' | translate}}</label>
            <fa-icon [icon]="icons.faEnvelope" class="input-icon"></fa-icon>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="isPhoneValid">
        <div class="col-12">
          <div class="form-group">
            <input type="text" id="phone" class="form-control" placeholder=""
                   [value]="getPhoneNumberString()" readonly="true" data-cy="enroll-template-phone">
            <fa-icon [icon]="icons.faPhone" class="input-icon"></fa-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer my-3">
      <div class="col d-flex justify-content-center flex-md-row flex-column">
        <button type="button" *ngIf="showEmail" class="btn btn-outline-primary me-md-3 w-30 send-button mb-3 mb-sm-0" (click)="sendCodeByEmail()" data-cy="send-email">
          <fa-icon [icon]="icons.faEnvelopeOpenText"></fa-icon>
          {{ 'profile.login_and_security.two_step_security.receive_by_email' | translate }}
        </button>
        <button *ngIf="isPhoneValid" type="button" class="btn btn-outline-primary w-30 send-button" (click)="sendCodeBySMS()" data-cy="send-sms">
          <fa-icon [icon]="icons.faMobileAlt"></fa-icon>
          {{ 'profile.login_and_security.two_step_security.receive_by_text' | translate }}
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!loading && template === modalTabs.SELECT_VERIFY_CODE_TEMPLATE">
    <div class="modal-header">
      <h3 class="modal-title" data-cy="mfa-modal-title">{{ 'profile.login_and_security.two_step_security.title' | translate }}</h3>
    </div>
    <div class="modal-body">
      <p class="secondary-content">{{ 'profile.login_and_security.two_step_security.select_verify_code.title' | translate }}</p>
    </div>
    <div class="modal-footer my-3">
      <div class="col d-flex justify-content-center flex-md-row flex-column">
        <button *ngIf="showEmail" type="button" class="btn btn-outline-primary me-md-3 w-30 send-button mb-3 mb-sm-0" (click)="sendCodeByEmail()" data-cy="send-email">
          <fa-icon [icon]="icons.faEnvelopeOpen" class="icons"></fa-icon>
          {{ 'profile.login_and_security.two_step_security.receive_by_email' | translate }}
        </button>
        <button *ngIf="isPhoneValid" type="button" class="btn btn-outline-primary w-30 send-button" (click)="sendCodeBySMS()" data-cy="send-sms">
          <fa-icon [icon]="icons.faMobileAlt" class="icons"></fa-icon>
          {{ 'profile.login_and_security.two_step_security.receive_by_text' | translate }}
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!loading && template === modalTabs.VERIFY_CODE_TEMPLATE">
    <div class="modal-header pb-0">
      <h3 class="modal-title">{{ 'profile.login_and_security.two_step_security.title' | translate }}</h3>
    </div>
    <div class="modal-body container">
      <ng-container *ngIf="!isMaxAttempts; else maxAttempts">
        <p *ngIf="!enrollBySmsActive" class="secondary-content mb-3">
          {{ 'profile.login_and_security.two_step_security.verify_code.content_1' | translate }}
          <span class="fw-bold fst-italic">{{ getEmailString() }}</span>
        </p>
        <p *ngIf="enrollBySmsActive" class="secondary-content mb-3">
          {{ 'profile.login_and_security.two_step_security.verify_code.content_2' | translate }}
          <span class="fw-bold fst-italic">{{ getPhoneNumberString() }}</span>
        </p>
        <p *ngIf="userData.account.mfaEmail === 0" class="secondary-content">{{ 'profile.login_and_security.two_step_security.verify_code.content_3' | translate }}</p>
      </ng-container>
      <ng-template #maxAttempts>
        <p class="secondary-content">{{ 'profile.login_and_security.two_step_security.verify_code.content_4' | translate }}</p>
        <p class="secondary-content">{{ 'profile.login_and_security.two_step_security.verify_code.content_5' | translate }}</p>
      </ng-template>

      <div class="row justify-content-center">
        <div class="col">
          <verification-code-form
            [showVerifyInsteadButton]="showVerifyInsteadButton"
            [showResendButton]="showResendCodeButton"
            [resendSuccess]="codeResendSuccess"
            [enrollBySms]="enrollBySmsActive"
            [expireTime]="expireTime"
            [isMaxAttempts]="isMaxAttempts"
            (switchMethodAction)="switchMethod()"
            (reSendCode)="resendCode()"
            (codeSubmitted)="verifyCode($event)">
          </verification-code-form>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<div class="two-step-modal" data-cy="mfa-two-step-modal" *ngIf="!showEmail && !isPhoneValid">
  <button type="button" [class]="fromLogin ? 'btn-close sign-out-button' : 'btn-close close-button'" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">{{ fromLogin ? ('sign_out' | translate) : '&times;' }}</span>
  </button>
  <div class="modal-header">
    <h3 class="modal-title" data-cy="mfa-modal-title">{{ 'profile.login_and_security.two_step_security.title' | translate }}</h3>
  </div>
<div class="modal-body">
  <div class="row d-flex">
    <div class="col-12 text-center">
      {{ 'ask_for_email_modal.customer_support_message' | translate }}
    </div>
  </div>
</div>
</div>
