import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import * as fromAuth from 'app/auth/store';
import { AuthApiActions, MultifactorAuthenticationActions } from 'app/auth/store/actions';
import { MfaMediums } from 'app/profile/models/mfa';
import { UnlinkEmailModal } from 'app/shared/components/unlink-email-modal/unlink-email-modal';
import { Modal } from 'app/shared/models/modal.model';
import { AnalyticsService } from 'app/shared/services/analytics/analytics.service';
import { AuthService } from 'app/shared/services/auth/auth.service';
import { MemberService } from 'app/shared/services/member/member.service';
import { ModalActions } from 'app/shared/store/actions';
import * as fromRoot from 'reducers';
import { Subject, combineLatest } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import * as fromShared from '../../shared/store/reducers';

@Component({
  selector: 'first-time-login',
  templateUrl: './first-time-login.html'
})

export class FirstTimeLoginPage implements OnInit, OnDestroy {
  getFirstTimeLogin$ = this.store.pipe(select(fromAuth.getFirstTimeLogin));
  public user$ = this.store.pipe(select(fromAuth.getUser));
  airlines$ = this.store.pipe(select(fromShared.getAirlines))
  private ngUnsubscribe$ = new Subject<void>();
  private redirect;
  private prepopulateEmail: string;
  user;
  public emailSent = false;

  private readonly EMAIL_IN_USE = 'The email has already been registered'

  constructor(
    private router: Router,
    private authService: AuthService,
    private store: Store<fromRoot.State>,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private analyticsService: AnalyticsService,
    private memberService: MemberService
  ) {}

  ngOnInit() {
    this.getFirstTimeLogin$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((firstTimeLogin) => {
      if (!firstTimeLogin) {
        return this.router.navigate(['/']);
      }
    });
    this.user$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((user) => {
      this.user = user;
    });
    this.activatedRoute.queryParams.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((params) => {
      this.redirect = params['redirect'] === '/first_time/new' ? null : params['redirect'];
      if (params.email) {
        this.prepopulateEmail = params.email;
      }
    });
  }

  performFirstTimeLogin(credentials) {
    combineLatest([this.airlines$.pipe(take(1)), this.authService.firstTime(this.prepareParams(credentials)).pipe(take(1))]).subscribe(
      async ([airlines, response]) => {
        this.store.dispatch(new AuthApiActions.FirstTimeLoginSuccess());
        localStorage.setItem('dontAskAgain', 'true');
        const params = { mfaEmail: 1, medium: MfaMediums.EMAIL };
        this.store.dispatch(new MultifactorAuthenticationActions.Enroll({ params }));
        this.store.dispatch(new AuthApiActions.UpdateUserAccountInfo({ ...params, personalEmail: credentials.email, homePhone: credentials.homePhone }));
        this.store.dispatch(new AuthApiActions.LoadUserConfigSuccess());
        if (this.user.id90Status === 'active') {
          this.store.dispatch(new AuthApiActions.LoadStatistics());
         }
        const navigate = this.redirect ? this.redirect : response.redirect;
        await this.analyticsService.logFirebaseSessionEvent({ user: this.user }, airlines, 'sign_up');
        return this.router.navigateByUrl(navigate);
      },
      (e: any) => {
        if (e) {
          if (e?.error?.error?.includes(this.EMAIL_IN_USE)) {
            const modalRef = this.modalService.open(UnlinkEmailModal, { size: 'lg' });
            modalRef.componentInstance.email = credentials.email;
            return modalRef.result.then((result) => {
              if (result) {
                this.emailSent = true;
              }
            });
          }
          const data: Modal = {
            body: e?.error?.error,
            modalOptions: { size: 'md' }
          };
          this.store.dispatch(new ModalActions.Show({ data }));
        }
      });
  }

  cancelFirstTimeLogin() {
    this.store.dispatch(new fromAuth.LogoutComponentActions.Logout());
  }

  prepareParams(credentials) {
    return {
      email: credentials.email,
      old_password: credentials.oldPassword,
      new_password: credentials.newPassword,
      confirm_new_password: credentials.newPasswordConfirm,
      phone_number: credentials.homePhone
    };
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
