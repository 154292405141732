import { Injectable } from '@angular/core';
import { HotelRoom } from 'app/hotels/models/hotel-room';
import { AmenityService } from 'app/hotels/services/amenity/amenity.service';
import { CurrencyHelper } from 'app/shared/helpers/currency.helper';
import { StealthCreditsHelper } from '../helpers/stealth-credits.helper';
import { keys } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class RoomHelper {
  private readonly filterMap = {
    all_rates: {
      key: 'all_rates',
      iconClass: 'faBuilding',
      order: 0,
      wide: false,
      fn: (r: HotelRoom) => true,
      active: true
    },
    free_cancellation: {
      key: 'free_cancellation',
      iconClass: 'faCalendarCheck',
      order: 1,
      wide: false,
      fn: this.isFreeCancellation
    },
    partially_refundable: {
      key: 'partially_refundable',
      iconClass: 'faHandHoldingUsd',
      order: 2,
      wide: false,
      fn: this.isPartiallyRefundable
    },
    non_refundable: {
      key: 'non_refundable',
      iconClass: 'faCalendarTimes',
      order: 3,
      wide: false,
      fn: this.isNonRefundable
    },
    pay_later: {
      key: 'pay_later',
      iconClass: 'faHistory',
      order: 4,
      wide: false,
      fn: this.isPayLater
    },
  };

  constructor(
    private amenityService: AmenityService,
    private currencyHelper: CurrencyHelper,
    private stealthCreditsHelper: StealthCreditsHelper,
  ) {}

  extractFilters(rooms: HotelRoom[]) {
    const filters = [];

    rooms.forEach((room: HotelRoom) => {
      room.value_add_items.forEach((item: any) => {
        const mappedFilter = this.amenityService.getMappedAmenity(item)[0];
        if (mappedFilter) {
          const found = filters.find((filter) => {
            return filter.name === mappedFilter.name;
          });

          if (!found) {
            filters.push({ ...mappedFilter, count: 1 }); // mappedFilter is not extensible
          } else {
            found.count += 1;
          }
        }
      });
    });

    return filters.filter(
      filter => filter.count ? filter.count < rooms.length : true
    ).filter(
      filter => filter.name.toLowerCase() !== 'pay later'
    ).sort((a, b) => {
      if (a.order > b.order) return 1;
      return b.order > a.order ? -1 : 0;
    });
  }

  extractRateTypes(rooms: HotelRoom[]) {
    const availableRateTypes: any[] = keys(this.filterMap).map(k => ({ key: k, count: 0 }));

    rooms.forEach((room: HotelRoom) => {
      availableRateTypes.forEach((rt) => {
        if (this.filterMap[rt.key].fn(room)) {
          rt.count++;
        }
      });
    });

    // Always show 'All Rates'
    availableRateTypes[0].count = 1;

    return availableRateTypes
      .filter(rt => rt.count > 0 && rt.count < rooms.length)
      .map(rt => this.filterMap[rt.key])
      .sort((a, b) => {
        if (a.order > b.order) return 1;
        return b.order > a.order ? -1 : 0;
      });
  }

  setActiveRoomFilters(filters: any[], activeFilters: string[]) {
    filters.forEach(f => f.active = activeFilters.includes(f.name));
  }

  setActiveRateTypes(rateTypes: any[], activeRateTypes: string[]) {
    rateTypes.forEach(rt => rt.active = activeRateTypes.includes(rt.key));
  }

  filterRoomsByFilters(rooms: HotelRoom[], filters: string[]) {
    let filteredRooms = rooms;

    if (filters.length > 0) {
      filteredRooms = rooms.filter(
        room => filters.every(
          filter => room.value_add_items.find(
            (item) => {
              const mappedFilter = this.amenityService.getMappedAmenity(item)[0];
              return mappedFilter && mappedFilter.name === filter;
            }
          )
        )
      );
    }

    return filteredRooms;
  }

  filterRoomsByRateTypes(rooms: HotelRoom[], rateTypes: string[]) {
    let filteredRateTypes = rooms;

    if (rateTypes.length > 0) {
      filteredRateTypes = rooms.filter(
        room => rateTypes.every(
          rateType => this.filterMap[rateType].fn(room)
        )
      );
    }

    return filteredRateTypes;
  }

  setupDisplayableRate(rooms: HotelRoom[], meta) {
    if (rooms.length < 1) return;
    rooms.forEach(room => room.displayable_rate = this.calcDisplayRate(room.display_rate, meta));
  }

  sortRoomsByGroupIndex(rooms: HotelRoom[]) : HotelRoom[] {
    if (!rooms || rooms.length < 2) return rooms;

    const sortedRooms: HotelRoom[] = rooms.sort((a, b) => {
      return this.compareGroupIndex(a, b);
    });
    return this.regroup(sortedRooms);
  }

  regroup(rooms: HotelRoom[]) : HotelRoom[] {
    if (!rooms || rooms.length < 3) return rooms;
    const groupings = rooms
      .reduce((g, r) => { this.mappingOfGroups(r, g); return g; }, { keys: [], nodes: {} });
    const counter = { index: 0 };
    groupings.keys.forEach((key) => { this.syncResults(rooms, counter, groupings.nodes[key]); });
    return rooms;
  }

  syncResults(results : HotelRoom[], counter : any, groupNode : any) {
    if (groupNode.values && groupNode.values.length > 0) {
      groupNode.values.sort(function(a, b) {
        if (a.displayable_rate['trueRate'] === b.displayable_rate['trueRate']) return 0;
        return a.displayable_rate['trueRate'] > b.displayable_rate['trueRate'] ? 1 : -1;
      }).forEach((value) => {
        results[counter.index] = value;
        counter.index++;
      });
    }
    if (!groupNode.child || !groupNode.child.keys || groupNode.child.keys.length < 1) return;
    groupNode.child.keys.forEach((key) => { this.syncResults(results, counter, groupNode.child.nodes[key]); });
  }

  mappingOfGroups(room: HotelRoom , group: any) {
    const groupIndex = room.group_index;
    const lastIndex = groupIndex.values.length - 1;
    let groupMap : any = null;
    for (let i = 0; i <= lastIndex; i++) {
      groupMap = this.groupMap(
        groupIndex.values[i], i !== lastIndex ? null : room, i === 0 ? group : groupMap.child);
    }
  }

  groupMap(k: number, v: any, group: any) : any {
    let groupNode = group.nodes ? group.nodes[k] : null;
    if (groupNode) return this.groupNodeWithValue(v, groupNode);
    groupNode = this.groupNodeWithValue(v, { key: k, child: { } });
    if (!group.keys) group.keys = [];
    if (!group.nodes) group.nodes = {};
    group.keys.push(k);
    group.nodes[k] = groupNode;
    return groupNode;
  }

  groupNodeWithValue(v: any, gn: any) : any {
    if (!v) return gn;
    if (!gn.values) gn.values = [];
    gn.values.push(v);
    return gn;
  }

  compareGroupIndex(roomA: HotelRoom, roomB: HotelRoom) : number {
    const a = roomA.group_index;
    const b = roomB.group_index;
    let result = this.compareAnyNull(a, b);
    if (result) return result;

    result = this.compareAnyNull(a.values, b.values);
    if (result) return result;

    const minKeySize = Math.min(a.values.length, b.values.length);
    for (let i = 0; i < minKeySize; i++) {
      if (this.compareNumber(a.values[i], b.values[i]) !== 0) return this.compareNumber(a.index, b.index);
    }
    return this.compareNumber(a.index, b.index);
  }

  compareNumber(a: number, b: number) : number {
    if (a === b) return 0;
    return a > b ? 1 : -1;
  }

  compareAnyNull(a: any, b: any) : number {
    if (!a && !b) return 0;
    if (!a) return 1;
    if (!b) return -1;
    return null;
  }

  isFreeCancellation(room: HotelRoom): boolean {
    return room.refundable === true
      && typeof(room.free_cancellation_datetime) !== 'undefined'
      && room.free_cancellation_datetime !== '';
  }

  isPartiallyRefundable(room: HotelRoom): boolean {
    return room.refundable_verbose === 'PARTIALLY REFUNDABLE';
  }

  isPayLater(room: HotelRoom): boolean {
    return room.payment_date === 'checkIn' || room.payment_date === 'later';
  }

  displayPrepaidInformation(room: HotelRoom) {
    if (!this.isPayLater(room)) return false;
    if (room.itinerary_id) return room.refundable !== 'REFUNDABLE';
    return !this.isFreeCancellation(room);
  }

  isRawCCOnly(rid) {
    const rateTypeCode = this.getRateTypeCode(rid);
    return ['BKG', 'RET'].includes(rateTypeCode);
  }

  calcDisplayRate(tRate, meta, digitsInfo = '1.0-0', optionalParams?) {
    let trueRate = tRate;
    if (optionalParams) {
      const totalCreditsApplied  = this.stealthCreditsHelper.getCreditsAppliedTotal(
        optionalParams.room,
        optionalParams.userCredits);
      const canApplyCreditsInSelectedRoom = this.stealthCreditsHelper.canCreditsBeAppliedToRate(optionalParams.room.hotel_id);
      if (canApplyCreditsInSelectedRoom && optionalParams.userApplyCredit) {
        trueRate = trueRate - totalCreditsApplied;
      }
    }

    const rate = this.currencyHelper.toSelectedCurrencySymbol(
      trueRate,
      meta.summary.applied_currency_exchange_rate.to,
      digitsInfo);
    let currency;
    let amount;
    if (isNaN(parseInt(rate[0], 10))) {
      const firstNumber = rate.search(/\d/);
      currency = rate.slice(0, firstNumber);
      amount = rate.slice(firstNumber, rate.length);
    } else if (isNaN(parseInt(rate[-1], 10))) {
      currency = rate[-1];
      amount = rate.slice(0, rate.length - 1);
    } else {
      amount = rate;
    }

    return { amount, currency, trueRate };
  }

  mapAmenities(room: HotelRoom)  {
    const mappedAmenities = [];

    if (room.amenities) {
      room.amenities.forEach((amenity) => {
        const mappedAmenity = this.amenityService.getMappedAmenity(amenity);

        if (mappedAmenity.length > 0) {
          const existingAmenity = mappedAmenities.find(a => a.name === mappedAmenity[0].name);
          if (!existingAmenity) {
            mappedAmenities.push(mappedAmenity[0]);
          }
        } else {
          mappedAmenities.push({ name: amenity.description, icon: 'icon-checkmark' });
        }
      });
    }

    return mappedAmenities;
  }

  mapValueAddItems(room: HotelRoom)  {
    const mappedValueAddItems = [];

    if (room.value_add_items) {
      room.value_add_items.forEach((amenity) => {
        const mappedValueAddItem = this.amenityService.getMappedAmenity(amenity);

        if (mappedValueAddItem.length > 0) {
          const existingAmenity = mappedValueAddItems.find(a => a.name === mappedValueAddItem[0].name);
          if (!existingAmenity) {
            mappedValueAddItems.push(mappedValueAddItem[0]);
          }
        } else {
          mappedValueAddItems.push({ name: amenity.name, icon: 'icon-checkmark' });
        }
      });
    }

    return mappedValueAddItems;
  }

  mapMessageAddItems(room: HotelRoom)  {
    const mappedMessageAddItems = [];

    if (room.message_add_items) {
      room.message_add_items.forEach((amenity) => {
        const mappedItem = this.amenityService.getMappedAmenity(amenity);

        if (mappedItem.length > 0) {
          const existingAmenity = mappedMessageAddItems.find(a => a.name === mappedItem[0].name);
          if (!existingAmenity) {
            mappedMessageAddItems.push(mappedItem[0]);
          }
        } else {
          mappedMessageAddItems.push({ name: amenity.name, icon: 'fa-exclamation-circle' });
        }
      });
    }

    return mappedMessageAddItems;
  }

  /**
   * This function determine if a room is non-refundabe using as first approach the first penalty window
   * If there are not penalties for the room, then check refundable attribute
   * @param room
   */
  isNonRefundable(room: HotelRoom): boolean {
    if (room.penalties === null || room.penalties.length === 0) {
      return (room.refundable !== true);
    }
    const firstPenaltyWindow = room.penalties[0];
    return (
      firstPenaltyWindow.hasOwnProperty('fullPenalty') &&
      firstPenaltyWindow.fullPenalty !== null &&
      firstPenaltyWindow.fullPenalty
    );
  }

  getRateTypeCode(id) {
    const key = id.substr(2, 1);
    switch (key)
    {
      case '1':
        return 'PKG';
      case '2':
        return 'RMP';
      case '3':
        return 'RET';
      case '5':
        return 'AGD';
      case '7':
        return 'BKG';
      default:
        return key;
    }
  }
}
