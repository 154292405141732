<json-ld [json]="schema"></json-ld>
<span itemscope itemtype="http://schema.org/WebSite">
  <meta itemprop="url" [content]="'branding.id90.domain' | translate" />
  <meta itemprop="name" [content]="'branding.id90.site_name' | translate">
</span>
<loading-indicator *ngIf="loading"></loading-indicator>
<div *ngIf="!loading && story; else defaultHomePageContent">
  <body>
    <!-- START - Banner Section -->
    <section class="banner-section" *ngIf="story.content.hero_section">
      <div class="container">
        <div class="row banner-row">
          <div class="col-6 col-lg-6 col-xl-6 d-none d-lg-block">
            <div class="card banner-card pb-4 pt-4">
              <div class="card-body" [appStoryblok]="story.content.hero_section[0]._editable">
                <div class="card-title text-white primary-content pt-4">
                  <h3> {{ 'web.homepage.travel_offers_text.part_1' | translate }}<span class="fw-bold"> {{ 'web.homepage.travel_offers_text.part_2' | translate }}</span> {{ 'web.homepage.travel_offers_text.part_3' | translate }}<div class="travel-employees"> {{ 'web.homepage.travel_offers_text.part_4' | translate }} </div></h3>
                </div>
                <div class="row d-block d-sm-none pt-4">
                  <div class="col">
                    <button type="button" class="btn btn-primary" [routerLink]="(story.content.hero_section[0].button[0].link.url)" (click)="trackButtonClick('Banner Section Button', 'Sign Up')"> {{ story.content.hero_section[0].button[0].optional_label }} </button>
                  </div>
                </div>
                <div class="row d-none d-sm-block pt-4">
                  <div class="col">
                    <button type="button" class="btn btn-primary" [routerLink]="(story.content.hero_section[0].button[0].link.url)" (click)="trackButtonClick('Banner Section Button', 'Sign Up')"> {{ story.content.hero_section[0].button[0].label }} </button>
                  </div>
                </div>
                <div class="row pt-2 pb-4">
                  <div class="col">
                    <span class="tertiary-content text-white"> {{ story.content.hero_section[0].text }} </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-12 d-lg-none text-center">
            <div class="card banner-card pb-4 pt-4">
              <div class="card-body" [appStoryblok]="story.content.hero_section[0]._editable">
                <div class="card-title text-white primary-content pt-4">
                  <h3> {{ 'web.homepage.travel_offers_text.part_1' | translate }}<span class="fw-bold"> {{ 'web.homepage.travel_offers_text.part_2' | translate }}</span> {{ 'web.homepage.travel_offers_text.part_3' | translate }}<div class="travel-employees"> {{ 'web.homepage.travel_offers_text.part_4' | translate }} </div></h3>
                </div>
                <div class="row d-block d-sm-none pt-4">
                  <div class="col">
                    <button type="button" class="btn btn-primary" [routerLink]="(story.content.hero_section[0].button[0].link.url)" (click)="trackButtonClick('Banner Section Button', 'Sign Up')"> {{ story.content.hero_section[0].button[0].optional_label }} </button>
                  </div>
                </div>
                <div class="row d-none d-sm-block pt-4">
                  <div class="col">
                    <button type="button" class="btn btn-primary" [routerLink]="(story.content.hero_section[0].button[0].link.url)" (click)="trackButtonClick('Banner Section Button', 'Sign Up')"> {{ story.content.hero_section[0].button[0].label }} </button>
                  </div>
                </div>
                <div class="row pt-2 pb-4">
                  <div class="col">
                    <span class="tertiary-content text-white"> {{ story.content.hero_section[0].text }} </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"></div>
        </div>
      </div>
    </section>

    <!-- END - Banner Section -->

    <!-- START - Travel the way Section -->
    <section *ngIf="story.content.introductory">
      <div class="container container-with-padding">
        <div class="row">
          <div class="col-6 col-lg-6 col-xl-6 travel-the-way d-none d-lg-block">
            <div class="primary-content" [appStoryblok]="story.content.introductory[0]._editable">
              <h3 > {{ story.content.introductory[0].title }} </h3>
            </div>
            <p class="pt-1 secondary-content text-start" [appStoryblok]="story.content.introductory[1]._editable" [innerHTML]="parseRichText(story.content.introductory[1].richtext.content[0])"></p>
          </div>
          <div class="col-12 col-sm-12 col-md-12 d-lg-none text-center">
            <div class="primary-content" [appStoryblok]="story.content.introductory[0]._editable">
              <h3 > {{ story.content.introductory[0].title }} </h3>
            </div>
            <p class="pt-1 secondary-content text-start" [appStoryblok]="story.content.introductory[1]._editable" [innerHTML]="parseRichText(story.content.introductory[1].richtext.content[0])"></p>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 image-container">
            <iframe class="video-iframe img-fluid" (click)="trackButtonClick('Travel the Way Section Frame', 'Youtube Frame Click')" [appStoryblok]="story.content.introductory[2]._editable" [src]="(story.content.introductory[2].video_id) | safe " title="Who We Are" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </section>
    <!-- END - Travel the way Section -->

    <!-- START - Why book Section -->
    <section class="gray" *ngIf="story.content.benefits">
      <div class="container container-with-padding">
        <div class="primary-content text-center" [appStoryblok]="story.content.benefits[0]._editable">
          <h3> {{ story.content.benefits[0].title }} </h3>
        </div>
        <div class="row pt-4 mt-4">
          <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 text-center p-4" *ngFor="let item of getArrayByComponentType(story.content.benefits, 'icons')" [appStoryblok]="item._editable">
            <img [src]="getImageUrlResized(item.image.filename, 100)" class="img-fluid icons" />
            <div class="primary-content pt-3">
              <h4>{{ item.text }}</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- END - Why book Section -->

    <!-- START - Explore your way section -->
    <section *ngIf="story.content.featured_destinations">
      <div class="container-fluid explore-your-way-container">
        <div class="row text-center">
          <div class="col-sm-1 col-md-1 col-lg-1 col-xl-2"></div>
          <div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-8">
            <div class="card explore-card">
              <div class="card-body">
                <div class="card-title primary-content" [appStoryblok]="story.content.featured_destinations[0]._editable">
                  <h3 class="pt-4"> {{ story.content.featured_destinations[0].title }} </h3>
                </div>
                <div class="row text-center justify-content-center pt-4 pb-4">
                  <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 pb-3" *ngFor="let item of getArrayByComponentType(story.content.featured_destinations, 'destination_card')" [appStoryblok]="item._editable">
                    <img class="destination-image" [src]="getImageUrlResized(item.image.filename, 400)" [alt]="item.alt"/>
                    <div class="primary-content pt-3">
                      {{ item.destination }}
                    </div>
                    <div class="pt-2">
                      {{ item.price }}
                    </div>
                  </div>
                </div>
                <div class="row d-block d-sm-none text-center pt-2" [appStoryblok]="story.content.featured_destinations[4]._editable">
                  <div class="col">
                    <button type="button" class="btn btn-primary" [routerLink]="story.content.featured_destinations[4].link.url" (click)="trackButtonClick('Explore your Way Section Button', 'Sign Up')"> {{ story.content.featured_destinations[4].optional_label }} </button>
                  </div>
                </div>
                <div class="row d-none d-sm-block text-center pt-4">
                  <div class="col">
                    <button type="button" class="btn btn-primary" [routerLink]="story.content.featured_destinations[4].link.url" (click)="trackButtonClick('Explore your Way Section Button', 'Sign Up')"> {{ story.content.featured_destinations[4].label }} </button>
                  </div>
                </div>
                <div class="row pt-2 pb-4">
                  <div class="col" [appStoryblok]="story.content.hero_section[0]._editable">
                    <span> {{ story.content.hero_section[0].text }} </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-1 col-md-1 col-lg-10 col-xl-2"></div>
        </div>
      </div>
    </section>
    <!-- END - Explore your way section -->

    <!-- START - Companies section -->
    <section *ngIf="story.content.trusted_companies">
      <div class="container-fluid container-with-padding">
        <div class="row">
          <div class="col text-center">
            <div class="primary-content" [appStoryblok]="story.content.trusted_companies[0]._editable">
              <h3>{{ story.content.trusted_companies[0].title }}</h3>
            </div>
          </div>
        </div>
        <div class="row pt-4">
          <div class="col-12" [appStoryblok]="story.content.trusted_companies[1]._editable">
            <companies-carousel-component [companyLogosPaths]="getTrustedCompanyLogos(story.content.trusted_companies[1].logos)"></companies-carousel-component>
          </div>
        </div>
        <div class="row pt-4" [appStoryblok]="story.content.trusted_companies[2]._editable">
          <div class="col text-center tertiary-content">{{ story.content.trusted_companies[2].label }}</div>
        </div>
      </div>
    </section>
    <!-- END - Companies section -->

    <!-- START - Staff travel section -->
    <section class="gray" *ngIf="story.content.automate_staff_traveler">
      <div class="container container-with-padding gray">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 pb-3" [appStoryblok]="story.content.automate_staff_traveler[0]._editable">
            <div class="primary-content">
              <h3> {{ story.content.automate_staff_traveler[0].title }} </h3>
            </div>
            <div class="secondary-content text-start pb-4">
              <p class="pt-1" [innerHTML]="parseRichText(story.content.automate_staff_traveler[0].richtext.content[0])"></p>
              <p class="pt-1" [innerHTML]="parseRichText(story.content.automate_staff_traveler[0].richtext.content[1])"></p>
            </div>
            <a [appStoryblok]="story.content.automate_staff_traveler[0].button[0]._editable" [target]="(story.content.automate_staff_traveler[0].button[0].link.url | safe ) " [href]="(story.content.automate_staff_traveler[0].button[0].link.url | safe )" class="pt-2">
              <button type="button" class="btn btn-primary pt-2 learn-more-button" (click)="trackButtonClick('Staff Travel Section Button', 'Learn More')"> {{ story.content.automate_staff_traveler[0].button[0].label }} </button>
            </a>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 image-container">
            <img
              class="image-box img-fluid"
              [src]="getImageUrlResized(story.content.automate_staff_traveler[0].image.filename, 800)"
              alt="airport-staff"
            />
          </div>
        </div>
      </div>
    </section>
    <!-- END - Staff travel section -->

    <!-- START - Reviews section -->
    <section *ngIf="story.content.testimonials">
      <div class="container container-with-padding">
        <div class="primary-content text-center" [appStoryblok]="story.content.testimonials[0]._editable">
          <h3>{{ story.content.testimonials[0].title }}</h3>
        </div>
        <div class="row pt-4">
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pt-3" *ngFor="let item of getArrayByComponentType(story.content.testimonials, 'testimonials-card')" [appStoryblok]="item._editable">
            <review-static-card
              [cardTitle]="item.title"
              [cardText]="item.text"
              [cardAuthor]="item.name"
            ></review-static-card>
          </div>
        </div>
      </div>
    </section>
    <!-- END - Reviews section -->

    <!-- START - Download mobile app section -->
    <section *ngIf="story.content.mobile_app">
      <div class="container mobile-app-container-desktop d-none d-lg-block">
        <div class="row mobile-app-section" [appStoryblok]="story.content.mobile_app[0].mobile_app[0]._editable">
          <div class="col-6 position-relative">
            <img class="phone-frame" [src]="getImageUrlResized(story.content.mobile_app[0].mobile_app[0].image.filename, 500)" loading="lazy" />
          </div>
          <div class="col-6" [appStoryblok]="story.content.mobile_app[0].mobile_app[0]._editable">
            <div class="primary-content">
              <h3 class="text-white"> {{ story.content.mobile_app[0].mobile_app[0].title }} </h3>
            </div>
            <div class="secondary-content text-white pt-4"> {{ story.content.mobile_app[0].mobile_app[0].text }} </div>
            <div class="row pt-4 pb-4">
              <div class="col-12">
                <a [href]="(story.content.mobile_app[0].button[1].link.url | safe )" target="_blank" (click)="trackButtonClick('Download Mobile App Section Button', 'Apple Store')">
                  <img class="apple-store-badge" [src]="getImageUrlResized(story.content.mobile_app[0].button[1].img.filename, 300)" loading="lazy" />
                </a>
                <a [href]="(story.content.mobile_app[0].button[0].link.url | safe )" target="_blank" (click)="trackButtonClick('Download Mobile App Section Button', 'Google Play Store')">
                  <img class="play-store-badge" [src]="getImageUrlResized(story.content.mobile_app[0].button[0].img.filename, 300)" loading="lazy" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container mobile-app-container-mobile d-lg-none">
        <div class="row mobile-app-section" [appStoryblok]="story.content.mobile_app[0].mobile_app[0]._editable">
          <div class="col-12 text-center">
            <div class="primary-content">
              <h3 class="text-white"> {{ story.content.mobile_app[0].mobile_app[0].title }} </h3>
            </div>
            <div class="secondary-content text-start text-white pt-4"> {{ story.content.mobile_app[0].mobile_app[0].text }} </div>
            <div class="row pt-4 pb-4">
              <div class="col-12">
                <a [href]="(story.content.mobile_app[0].button[1].link.url | safe )" target="_blank" (click)="trackButtonClick('Download Mobile App Section Button', 'Apple Store')">
                  <img class="apple-store-badge" [src]="getImageUrlResized(story.content.mobile_app[0].button[1].img.filename, 300)" loading="lazy" />
                </a>
                <a [href]="(story.content.mobile_app[0].button[0].link.url | safe )" target="_blank" (click)="trackButtonClick('Download Mobile App Section Button', 'Google Play Store')">
                  <img class="play-store-badge" [src]="getImageUrlResized(story.content.mobile_app[0].button[0].img.filename, 300)" loading="lazy" />
                </a>
              </div>
            </div>
          </div>
          <div class="col-12 text-center phone-frame-container">
            <img class="phone-frame" [src]="getImageUrlResized(story.content.mobile_app[0].mobile_app[0].image.filename, 500)" loading="lazy" />
          </div>
        </div>
      </div>
    </section>
    <!-- END - Download mobile app section -->
  </body>
</div>

<ng-template #defaultHomePageContent>
  <body>
    <!-- START - Banner Section -->
    <section class="banner-section">
      <div class="container">
        <div class="row banner-row">
          <div class="col-6 col-lg-6 col-xl-6 d-none d-lg-block">
            <div class="card banner-card pb-4 pt-4">
              <div class="card-body">
                <div class="card-title text-white primary-content pt-4">
                  <h3> {{ 'web.homepage.travel_offers_text.part_1' | translate }}<span class="fw-bold"> {{ 'web.homepage.travel_offers_text.part_2' | translate }}</span> {{ 'web.homepage.travel_offers_text.part_3' | translate }}<div class="travel-employees"> {{ 'web.homepage.travel_offers_text.part_4' | translate }} </div></h3>
                </div>
                <div class="row d-block d-sm-none pt-4">
                  <div class="col">
                    <button type="button" class="btn btn-primary" routerLink="create-account" (click)="trackButtonClick('Banner Section Button', 'Sign Up')"> {{ 'web.homepage.sign_up_short' | translate }} </button>
                  </div>
                </div>
                <div class="row d-none d-sm-block pt-4">
                  <div class="col">
                    <button type="button" class="btn btn-primary" routerLink="create-account" (click)="trackButtonClick('Banner Section Button', 'Sign Up')"> {{ 'web.homepage.sign_up_long' | translate }} </button>
                  </div>
                </div>
                <div class="row pt-2 pb-4">
                  <div class="col">
                    <span class="tertiary-content text-white"> {{ 'web.homepage.sign_up_info' | translate }} </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-12 d-lg-none text-center">
            <div class="card banner-card pb-4 pt-4">
              <div class="card-body">
                <div class="card-title text-white primary-content pt-4">
                  <h3> {{ 'web.homepage.travel_offers_text.part_1' | translate }}<span class="fw-bold"> {{ 'web.homepage.travel_offers_text.part_2' | translate }}</span> {{ 'web.homepage.travel_offers_text.part_3' | translate }}<div class="travel-employees"> {{ 'web.homepage.travel_offers_text.part_4' | translate }} </div></h3>
                </div>
                <div class="row d-block d-sm-none pt-4">
                  <div class="col">
                    <button type="button" class="btn btn-primary" routerLink="create-account" (click)="trackButtonClick('Banner Section Button', 'Sign Up')"> {{ 'web.homepage.sign_up_short' | translate }} </button>
                  </div>
                </div>
                <div class="row d-none d-sm-block pt-4">
                  <div class="col">
                    <button type="button" class="btn btn-primary" routerLink="create-account" (click)="trackButtonClick('Banner Section Button', 'Sign Up')"> {{ 'web.homepage.sign_up_long' | translate }} </button>
                  </div>
                </div>
                <div class="row pt-2 pb-4">
                  <div class="col">
                    <span class="tertiary-content text-white"> {{ 'web.homepage.sign_up_info' | translate }} </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"></div>
        </div>
      </div>
    </section>

    <!-- END - Banner Section -->

    <!-- START - Travel the way Section -->
    <section>
      <div class="container container-with-padding">
        <div class="row">
          <div class="col-6 col-lg-6 col-xl-6 travel-the-way d-none d-lg-block">
            <div class="primary-content">
              <h3>{{ 'web.homepage.travel_the_way.title' | translate }}</h3>
            </div>
            <p class="pt-1 secondary-content text-start"> {{ 'web.homepage.travel_the_way.text_1' | translate }} </p>
            <p class="pt-1 secondary-content text-start"> {{ 'web.homepage.travel_the_way.text_2' | translate }} </p>
          </div>
          <div class="col-12 col-sm-12 col-md-12 d-lg-none text-center">
            <div class="primary-content">
              <h3>{{ 'web.homepage.travel_the_way.title' | translate }}</h3>
            </div>
            <p class="pt-1 secondary-content text-start"> {{ 'web.homepage.travel_the_way.text_1' | translate }} </p>
            <p class="pt-1 secondary-content text-start"> {{ 'web.homepage.travel_the_way.text_2' | translate }} </p>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 image-container">
            <iframe class="video-iframe img-fluid" (click)="trackButtonClick('Travel the Way Section Frame', 'Youtube Frame Click')" src="https://www.youtube.com/embed/OZdwq9fNDWg?rel=0" title="Who We Are" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </section>
    <!-- END - Travel the way Section -->

    <!-- START - Why book Section -->
    <section class="gray">
      <div class="container container-with-padding">
        <div class="primary-content text-center">
          <h3>{{ 'web.homepage.why_book.title' | translate }}</h3>
        </div>
        <div class="row pt-4 mt-4">
          <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 text-center p-4">
            <img
              [src]="'angular_assets/images/homepage/rate-icon.png'"
              class="img-fluid icons"
            />
            <div class="primary-content pt-3">
              <h4>{{ 'web.homepage.why_book.exclusive_rates' | translate }}</h4>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 text-center p-4">
            <img
              [src]="'angular_assets/images/homepage/currency-symbol-icon.png'"
              class="img-fluid icons"
            />
            <div class="primary-content pt-3">
              <h4>{{ 'web.homepage.why_book.save_on' | translate }}</h4>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 text-center p-4">
            <img
              [src]="'angular_assets/images/homepage/seats-icon.png'"
              class="img-fluid icons"
            />
            <div class="primary-content pt-3">
              <h4>{{ 'web.homepage.why_book.flight_loads' | translate }}</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- END - Why book Section -->

    <!-- START - Explore your way section -->
    <section>
      <div class="container-fluid explore-your-way-container">
        <div class="row text-center">
          <div class="col-sm-1 col-md-1 col-lg-1 col-xl-2"></div>
          <div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-8">
            <div class="card explore-card">
              <div class="card-body">
                <div class="card-title primary-content">
                  <h3 class="pt-4"> {{ 'web.homepage.explore_your_way.title' | translate }} </h3>
                </div>
                <div class="row text-center justify-content-center pt-4 pb-4">
                  <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 pb-3">
                    <img class="destination-image" [src]="'angular_assets/images/homepage/mexico-pyramid.jpg'" alt="mexico-img"/>
                    <div class="primary-content pt-3">
                      {{ 'web.homepage.explore_your_way.card_1.destination' | translate }}
                    </div>
                    <div class="pt-2">
                      {{ 'web.homepage.explore_your_way.card_1.price' | translate }}
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 pb-3">
                    <img class="destination-image" [src]="'angular_assets/images/homepage/turkey-hot-air-balloons.jpg'" alt="turkey-img"/>
                    <div class="primary-content pt-3">
                      {{ 'web.homepage.explore_your_way.card_2.destination' | translate }}
                    </div>
                    <div class="pt-2">
                      {{ 'web.homepage.explore_your_way.card_2.price' | translate }}
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 pb-3">
                    <img class="destination-image" [src]="'angular_assets/images/homepage/cruise.jpg'" alt="cruise-img"/>
                    <div class="primary-content pt-3">
                      {{ 'web.homepage.explore_your_way.card_3.destination' | translate }}
                    </div>
                    <div class="pt-2">
                      {{ 'web.homepage.explore_your_way.card_3.price' | translate }}
                    </div>
                  </div>
                </div>
                <div class="row d-block d-sm-none text-center pt-2">
                  <div class="col">
                    <button type="button" class="btn btn-primary" routerLink="create-account" (click)="trackButtonClick('Explore your Way Section Button', 'Sign Up')"> {{ 'web.homepage.sign_up_short' | translate }} </button>
                  </div>
                </div>
                <div class="row d-none d-sm-block text-center pt-4">
                  <div class="col">
                    <button type="button" class="btn btn-primary" routerLink="create-account" (click)="trackButtonClick('Explore your Way Section Button', 'Sign Up')"> {{ 'web.homepage.sign_up_long' | translate }} </button>
                  </div>
                </div>
                <div class="row pt-2 pb-4">
                  <div class="col"  *ngIf="story" [appStoryblok]="story.content.hero_section[0]._editable">
                    <span> {{ story.content.hero_section[0].text }} </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-1 col-md-1 col-lg-10 col-xl-2"></div>
        </div>
      </div>
    </section>
    <!-- END - Explore your way section -->

    <!-- START - Companies section -->
    <section>
      <div class="container-fluid container-with-padding">
        <div class="row">
          <div class="col text-center">
            <div class="primary-content">
              <h3>{{ 'web.homepage.trusted_companies.title' | translate }}</h3>
            </div>
          </div>
        </div>
        <div class="row pt-4">
          <div class="col-12">
            <companies-carousel-component></companies-carousel-component>
          </div>
        </div>
        <div class="row pt-4">
          <div class="col text-center tertiary-content">{{ 'web.homepage.trusted_companies.more' | translate }}</div>
        </div>
      </div>
    </section>
    <!-- END - Companies section -->

    <!-- START - Staff travel section -->
    <section class="gray">
      <div class="container container-with-padding gray">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 pb-3">
            <div class="primary-content">
              <h3> {{ 'web.homepage.automate_staff_travel.title' | translate }} </h3>
            </div>
            <div class="secondary-content text-start pb-4">
              <p class="pt-1"> {{ 'web.homepage.automate_staff_travel.text_1' | translate }} </p>
              <p class="pt-1"> {{ 'web.homepage.automate_staff_travel.text_2' | translate }} </p>
            </div>
            <a target="https://www.id90travel.com/about/ascend" href="https://www.id90travel.com/about/ascend" class="pt-2">
              <button type="button" class="btn btn-primary pt-2 learn-more-button" (click)="trackButtonClick('Staff Travel Section Button', 'Learn More')"> {{ 'web.homepage.automate_staff_travel.learn_more' | translate }} </button>
            </a>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 image-container">
            <img
              class="image-box img-fluid"
              [src]="'angular_assets/images/homepage/airport-staff.jpg'"
              alt="airport-staff"
            />
          </div>
        </div>
      </div>
    </section>
    <!-- END - Staff travel section -->

    <!-- START - Reviews section -->
    <section>
      <div class="container container-with-padding">
        <div class="primary-content text-center">
          <h3>{{ 'web.homepage.review_section.title' | translate }}</h3>
        </div>
        <div class="row pt-4">
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pt-3">
            <review-static-card
              [cardTitle]="'web.homepage.review_section.card_1.title' | translate"
              [cardText]="'web.homepage.review_section.card_1.text' | translate"
              [cardAuthor]="'web.homepage.review_section.card_1.author' | translate"
            ></review-static-card>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pt-3">
            <review-static-card
              [cardTitle]="'web.homepage.review_section.card_2.title' | translate"
              [cardText]="'web.homepage.review_section.card_2.text' | translate"
              [cardAuthor]="'web.homepage.review_section.card_2.author' | translate"
            ></review-static-card>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pt-3">
            <review-static-card
              [cardTitle]="'web.homepage.review_section.card_3.title' | translate"
              [cardText]="'web.homepage.review_section.card_3.text' | translate"
              [cardAuthor]="'web.homepage.review_section.card_3.author' | translate"
            ></review-static-card>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pt-3">
            <review-static-card
              [cardTitle]="'web.homepage.review_section.card_4.title' | translate"
              [cardText]="'web.homepage.review_section.card_4.text' | translate"
              [cardAuthor]="'web.homepage.review_section.card_4.author' | translate"
            ></review-static-card>
          </div>
        </div>
      </div>
    </section>
    <!-- END - Reviews section -->

    <!-- START - Download mobile app section -->
    <section>
      <div class="container mobile-app-container-desktop d-none d-lg-block">
        <div class="row mobile-app-section">
          <div class="col-6 text-end">
            <img class="phone-frame" [src]="'/angular_assets/images/homepage/phone-frame.png'" loading="lazy" />
          </div>
          <div class="col-6">
            <div class="primary-content">
              <h3 class="text-white"> {{ 'web.homepage.download_our_app.title' | translate }} </h3>
            </div>
            <div class="secondary-content text-white pt-4"> {{ 'web.homepage.download_our_app.text' | translate }} </div>
            <div class="row pt-4 pb-4">
              <div class="col-12">
                <a href="https://itunes.apple.com/us/app/id90-travel/id1032894432" target="_blank" (click)="trackButtonClick('Download Mobile App Section Button', 'Apple Store')">
                  <img class="apple-store-badge" [src]="'/angular_assets/images/homepage/apple-store.png'" loading="lazy" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.id90travel.id90app&hl=en_US" target="_blank" (click)="trackButtonClick('Download Mobile App Section Button', 'Google Play Store')">
                  <img class="play-store-badge" [src]="'/angular_assets/images/homepage/google-play-store.png'" loading="lazy" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container mobile-app-container-mobile d-lg-none">
        <div class="row mobile-app-section">
          <div class="col-12 text-center">
            <div class="primary-content">
              <h3 class="text-white"> {{ 'web.homepage.download_our_app.title' | translate }} </h3>
            </div>
            <div class="secondary-content text-start text-white pt-4"> {{ 'web.homepage.download_our_app.text' | translate }} </div>
            <div class="row pt-4 pb-4">
              <div class="col-12">
                <a href="https://itunes.apple.com/us/app/id90-travel/id1032894432" target="_blank" (click)="trackButtonClick('Download Mobile App Section Button', 'Apple Store')">
                  <img class="apple-store-badge" [src]="'/angular_assets/images/homepage/apple-store.png'" loading="lazy" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.id90travel.id90app&hl=en_US" target="_blank" (click)="trackButtonClick('Download Mobile App Section Button', 'Google Play Store')">
                  <img class="play-store-badge" [src]="'/angular_assets/images/homepage/google-play-store.png'" loading="lazy" />
                </a>
              </div>
            </div>
          </div>
          <div class="col-12 text-center phone-frame-container">
            <img class="phone-frame" [src]="'/angular_assets/images/homepage/phone-frame.png'" loading="lazy" />
          </div>
        </div>
      </div>
    </section>
    <!-- END - Download mobile app section -->
  </body>
</ng-template>
