import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faChevronLeft, faCircle, faSpinner, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UserCreditHistoryItem } from 'app/profile/models/user-credit-history.interface';
import { UserCredits } from 'app/profile/models/user-credits.interface';
import { CreditActions, CreditHistoryActions } from 'app/profile/store/actions';
import * as fromProfile from 'app/profile/store/reducers';
import { FormErrorsHelper } from 'app/shared/helpers/form-errors.helper';
import { GeneralHelper } from 'app/shared/helpers/general.helper';
import { InviteFriendsService } from 'app/shared/services/invite-friends/invite-friends.service';
import { PromoCodeCreditsService } from 'app/shared/services/promo-code-credits/promo-code-credits';
import { ModalActions } from 'app/shared/store/actions';
import { datesConfig } from 'configs/dates';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'hotel-credits',
  templateUrl: './hotel-credits.html',
  styleUrls: ['./hotel-credits.scss']
})

export class HotelCreditsComponent implements OnInit, OnDestroy {
  @ViewChild('reedemSection', { static: false }) reedemSection: ElementRef;

  @Input() userCredits: UserCredits;
  @Input() userCreditHistory: UserCreditHistoryItem[] = [];
  @Output() showTabs = new EventEmitter();
  @Output() updateProfile = new EventEmitter();

  creditPromoCodeForm: UntypedFormGroup;
  loading: boolean = false;
  success: boolean = false;
  error: string = null;
  promoCode: string = '';
  allCreditsExpire = true;
  isUsd = true;
  nextTierReward = 0;
  nextLevel = 0;
  nextTierLtv = 0;
  nextTierGapLtv = 0;
  currentLevel = 0;
  currentTierLtv = 0;
  size = 'big';
  showAwayFromNextLevel = false;
  showLevels = true;
  nextTierCurrentLtv = 0;
  creditPromoCodeValue;
  inviteCode;
  programDetailsLink = '';
  icons = { faChevronLeft, faCircle, faSpinner, faTrophy };

  private ngUnsubscribe = new Subject<void>();
  private memberLoyaltySummary$ = this.store.select(fromProfile.getMemberLoyalty);

  public showCreditHistoryModal: boolean = false;

  constructor(
    public formErrorsHelper: FormErrorsHelper,
    protected promoCodeCreditsService: PromoCodeCreditsService,
    private store: Store<any>,
    private modalService: NgbModal,
    private generalHelper: GeneralHelper,
    private activatedRoute: ActivatedRoute,
    private inviteFriendsService: InviteFriendsService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.prepareCodeParams();
    this.validateInviteCode();
    this.createForm();
    this.calcDoesAllCreditsExpire();
    this.isUsdCurrency();
    this.getMemberLoyalty();
    this.prepareScreen();
    this.programDetailsLink = this.translate.instant('profile.credits.program_details_link');
  }
  getMemberLoyalty() {
    this.memberLoyaltySummary$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((summary: any) => {
      if (summary) {
        this.currentLevel = summary.current_level;
        this.currentTierLtv = summary.current_tier_ltv;
        this.nextLevel = summary.next_level;
        this.nextTierReward = summary.next_tier_reward;
        this.nextTierLtv = summary.next_tier_ltv;
        this.nextTierGapLtv = summary.next_tier_gap_ltv;
        this.nextTierCurrentLtv = summary.next_tier_current_ltv;
      }
    });
  }

  toggleCreditHistory() {
    this.showCreditHistoryModal = !this.showCreditHistoryModal;
  }

  createForm() {
    this.creditPromoCodeForm = new UntypedFormGroup({
      creditPromoCodeValue: new UntypedFormControl('', Validators.required)
    });
    this.creditPromoCodeForm.get('creditPromoCodeValue').setValue(this.creditPromoCodeValue);
  }

  calcDoesAllCreditsExpire() {
    // get the latest expiration date
    if (this.userCreditHistory.length > 0) {
      const latestCreditTransaction = this.userCreditHistory.filter(c => parseFloat(c.amount) > 0)
        .reduce((a, b) => {
          return new Date(a.expiration_date) > new Date(b.expiration_date) ? a : b;
        });
      if (latestCreditTransaction) {
        // if latest expiration date is greater than the expiration of userCredits returned from service,
        // only partial credits are expiring.
        this.allCreditsExpire = !moment(latestCreditTransaction.expiration_date, datesConfig.momentFormats)
          .isAfter(moment(this.userCredits.expiration, datesConfig.momentFormats));
      }
    }
  }
  isUsdCurrency() {
    if (this.userCredits) {
      this.isUsd = this.userCredits.currency === '$';
    }
  }

  onRedeemCode(): void {
    if (!this.isValid()) {
      Object.keys(this.creditPromoCodeForm.controls).forEach((field) => {
        const control = this.creditPromoCodeForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
      return;
    }
    this.loading = true;
    this.success = null;
    this.error = null;
    this.promoCode = this.creditPromoCodeForm.get('creditPromoCodeValue').value;
    this.promoCodeCreditsService.redeemCreditsPromotionCode({ code: this.promoCode })
    .pipe(take(1)).subscribe(() => {
      this.generalHelper.scrollToHeader();
      this.loading = false;
      this.success = true;
      this.cleanCreditPromoCodeInput();
      this.updateMemberCredits();
    },
    (error) => {
      this.generalHelper.scrollToHeader();
      this.error = error.error.message;
      this.success = false;
      this.loading = false;
    }
    );
  }

  cleanCreditPromoCodeInput() {
    this.creditPromoCodeForm.get('creditPromoCodeValue').setValue('');
    this.creditPromoCodeForm.get('creditPromoCodeValue').setErrors(null);
  }

  updateMemberCredits() {
    this.store.dispatch(new CreditHistoryActions.LoadCredits());
    this.store.dispatch(new CreditActions.LoadCredits());

  }

  isValid() {
    return this.creditPromoCodeForm.valid;
  }

  closeAlert() {
    this.error = null;
    this.success = false;
  }

  backToTabs() {
    this.showTabs.emit();
  }

  get creditPromoCodeValueFormControl() {
    return this.creditPromoCodeForm.get('creditPromoCodeValue');
  }

  onModalOpen(content: TemplateRef<any>): void {
    this.modalService.open(content, { size: 'lg' });
  }

  onModalClose() {
    this.modalService.dismissAll();
  }

  private validateInviteCode() {
    if (this.inviteCode) {
      this.inviteFriendsService.validInviteCode(this.inviteCode).pipe(take(1)).subscribe((response: any) => {
        if (!response.enable_invite) {
          const data = {
            title: 'mobile.invite_friends.invite_expired_title',
            bodyTranslationKey: 'mobile.invite_friends.invite_expired_body'
          };
          this.store.dispatch(new ModalActions.Show({ data }));
          this.creditPromoCodeForm.get('creditPromoCodeValue').setValue('');
        }
      });
    }
  }

  private prepareCodeParams() {
    this.creditPromoCodeValue = this.activatedRoute.snapshot.queryParams['cc']
      ? this.activatedRoute.snapshot.queryParams['cc']
      : '';
    this.inviteCode = this.activatedRoute.snapshot.queryParams['ic']
      ? this.activatedRoute.snapshot.queryParams['ic']
      : '';
  }

  private prepareScreen() {
    if (window.innerWidth < 992 && this.creditPromoCodeValue) {
      setTimeout(() => {
        this.reedemSection && this.reedemSection.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      },         300);
    }

  }
  isMxnOrRub() {
    return this.userCredits.currency === '₽' || this.userCredits.currency ===  'MX$' ? 'credit-amount-rub-mxn' : 'credit-amount';
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
