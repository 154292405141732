<div class="modal-header pb-0">
  <div class="profile-title primary-content">{{ 'payment.add_payment_method' | translate }}</div>
  <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()">

  </button>
</div>
<div class="modal-body px-0 pb-4 pt-0">
  <ngb-alert *ngIf="error" type="danger" (closed)="closeError()">{{ error }}</ngb-alert>
  <payment
    [rawCCOnly]="true"
    [paymentInformation]="paymentInformation"
    [showIsBusinessCheckbox]="showIsBusinessCheckbox"
    [addressFields]="{showStreet: true, showZip: true, showCity: true, showCountry: true, showState: true}">
  </payment>
</div>
<div class="modal-footer">
  <button type="button" (click)="dismiss()" class="btn btn-outline-light text-dark border">
    {{ 'flights.search.modals.cancel' | translate }}
  </button>
  <button ngbAutofocus type="button" class="btn btn-md btn-primary" [disabled]="loading" (click)="onAddPaymentMethodClick()" data-cy="add-card">
    <fa-icon *ngIf="loading" [icon]="icons.faSpinner" class="rotation-animation fa-spin"></fa-icon>
    {{ 'payment.add_card' | translate }}
  </button>
</div>
