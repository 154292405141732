<div class="container">
  <div class="row pb-3" *ngIf="!cardToEdit">
    <div class="col primary-content primary-color">
      {{ 'mobile.checkout.select_card'  | translate }}
    </div>
    <button type="button" class="btn-close close-button pe-3" aria-label="Close" (click)="dismiss()">

    </button>
  </div>
  <ng-container [ngTemplateOutlet]="loadingNewCards ? loading : (cardToEdit ? (deleteCreditCard ? deleteCard : editCard) : cardList)">
  </ng-container>
</div>

<ng-template #cardList>
  <div class="clickable" *ngFor="let card of paymentMethods">
    <div class="w-100"  [ngClass]="{'mb-3': !card.isEditable }" (click)="!card.disabled && !card.isExpired && selectCard(card)">
      <payment-card [card]="card" [isEditableMode]="card.isEditable"></payment-card>
    </div>
    <div class="w-100 h-100 d-flex flex-column flex-md-row buttons-container mb-3 py-1" *ngIf="card.isEditable">
      <div class="col-md-3 col-xs-12 col-12 offset-md-2 order-2 order-md-1 d-flex justify-content-center">
        <button type="button" class="btn btn-link text-decoration-none text-danger gtm-manage-profile-cards" (click)="onPaymentMethodDeleted(card)" data-cy="delete-cc">
          {{ 'payment.delete_card' | translate }}
        </button>
      </div>
      <div class="col-md-3 col-xs-12 col-12 order-2 order-md-1 d-flex justify-content-center">
        <button type="button" class="btn btn-link text-decoration-none text-primary gtm-manage-profile-cards" (click)="onEditOpen(card)" data-cy="edit-cc">
          {{ 'payment.edit_card' | translate }}
        </button>
      </div>
      <div class="col-md-4 col-xs-12 col-12 order-1 order-md-2 d-flex align-items-center d-flex justify-content-center">
        <div *ngIf="card.isPrimary" class="badge rounded-pill text-bg-success"><fa-icon [icon]="icons.faCheckCircle" class="pe-2"></fa-icon>
          {{ 'mobile.profile.credit_cards.primary_card' | translate }}
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editCard>
  <edit-payment
    [isPopup]="false"
    [userData]="userData"
    [card]="cardToEdit"
    [requestMFA]="false"
    (updatedCard)="updatedCard($event)"
    (cancelPressed)="editCardCancelPressed($event)">
  </edit-payment>
</ng-template>

<ng-template #deleteCard>
  <div class="delete-payment-methods-container">
    <delete-payment-method
      [isPopup]="false"
      [userData]="userData"
      [requestMFA]="false"
      [token]="cardToEdit.token"
      (deletedCard)="updatedCard($event)"
      (cancelPressed)="editCardCancelPressed($event)">
  </delete-payment-method>
  </div>
</ng-template>


<ng-template #loading>
  <loading-indicator [mode]="'basic-height'"></loading-indicator>
</ng-template>
